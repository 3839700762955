export const PERSON_DATABASIC = 'PERSON_DATABASIC';
export const PERSON_SECURITY = 'PERSON_SECURITY';
export const PERSON_LIST_EMAIL = 'PERSON_LIST_EMAIL';
export const PERSON_REMOVE_EMAIL = 'PERSON_REMOVE_EMAIL';
export const PERSON_EDIT_EMAIL = 'PERSON_EDIT_EMAIL';
export const PERSON_LIST_CONTACT = 'PERSON_LIST_CONTACT';
export const PERSON_REMOVE_CONTACT = 'PERSON_REMOVE_CONTACT';
export const PERSON_EDIT_CONTACT = 'PERSON_EDIT_CONTACT';
export const PERSON_LIST_ADDRESS = 'PERSON_LIST_ADDRESS';
export const PERSON_REMOVE_ADDRESS = 'PERSON_REMOVE_ADDRESS';
export const PERSON_EDIT_ADDRESS = 'PERSON_EDIT_ADDRESS';
export const PERSON_SET_CLIENT = 'PERSON_SET_CLIENT';
export const PERSON_RESET_CLENT = 'PERSON_RESET_CLENT';
