import * as types from 'redux/constants/Auth';
import * as mutations from 'redux/mutations';

const initState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  token: localStorage.getItem(types.AUTH_TOKEN),
  loggedIn: true
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case types.AUTHENTICATED:
      return mutations.auth(state, action);

    case types.SHOW_AUTH_MESSAGE:
      return {
        ...state,
        message: action.message,
        showMessage: true,
        loading: false
      };
    case types.HIDE_AUTH_MESSAGE:
      return {
        ...state,
        message: '',
        showMessage: false
      };
    case types.SIGNOUT_SUCCESS: {
      return {
        ...state,
        token: null,
        redirect: '/',
        loading: false
      };
    }
    case types.SIGNUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        token: action.token
      };
    }
    case types.SHOW_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case types.SIGNIN_WITH_GOOGLE_AUTHENTICATED: {
      return {
        ...state,
        loading: false,
        token: action.token
      };
    }
    case types.SIGNIN_WITH_FACEBOOK_AUTHENTICATED: {
      return {
        ...state,
        loading: false,
        token: action.token
      };
    }
    default:
      return state;
  }
};

export default auth;
