export const GROUP_DATABASIC = 'GROUP_DATABASIC';
export const GROUP_LIST_PHONE = 'GROUP_LIST_PHONE';
export const GROUP_REMOVE_PHONE = 'GROUP_REMOVE_PHONE';
export const GROUP_EDIT_PHONE = 'GROUP_EDIT_PHONE';
export const GROUP_LIST_OWNER = 'GROUP_LIST_OWNER';
export const GROUP_REMOVE_OWNER = 'GROUP_REMOVE_OWNER';
export const GROUP_EDIT_OWNER = 'GROUP_EDIT_OWNER';
export const GROUP_REMOVE = 'GROUP_REMOVE';
export const GROUP_VIEW = 'GROUP_VIEW';
export const GROUP_RESET = 'GROUP_RESET';
