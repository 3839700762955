import * as mutations from 'redux/mutations/Client';
import data from 'redux/mocks/data-init-client.json';

const initState = {
  data
};

const person = (state = initState, action) => {
  switch (action.type) {
    case 'SET_CLIENT':
      return mutations.setClient(state, action);

    case 'SET_EMAIL':
      return mutations.setEmail(state, action);

    case 'REMOVE_EMAIL':
      return mutations.removeEmail(state, action);

    case 'EDIT_EMAIL':
      return mutations.editEmail(state, action);

    case 'SET_PHONE':
      return mutations.setPhone(state, action);

    case 'REMOVE_PHONE':
      return mutations.removePhone(state, action);

    case 'EDIT_PHONE':
      return mutations.editPhone(state, action);

    case 'RESET_CLENT':
      return mutations.resetClient(state);

    default:
      return state;
  }
};

export default person;
