import { findIndex } from 'lodash';

export const setDataBasic = (state, action) => {
  return {
    ...state,
    dataBasic: { ...state.dataBasic, ...action.group }
  };
};

export const setPhone = (state, action) => {
  return {
    ...state,
    phones: [...state.phones, { ...action.phone }]
  };
};

export const removePhone = (state, action) => {
  return {
    ...state,
    phones: [...action.phone]
  };
};

export const setEditPhone = (state, action) => {
  const edit = (phone, i) =>
    phone.key === action.phone.key
      ? (state.phones[i] = { ...state.phones[i], ...action.phone })
      : state.phones;
  return {
    ...state,
    phones: state.phones.map(edit)
  };
};

export const setOwner = (state, action) => {
  return {
    ...state,
    owners: [...action.owner]
  };
};

export const removeOwner = (state, action) => {
  return {
    ...state,
    owners: [...action.owner]
  };
};

export const setEditOwner = (state, action) => {
  const edit = (owner, i) =>
    owner.key === action.owner.key
      ? (state.owners[i] = { ...state.owners[i], ...action.owner })
      : state.owners;
  return {
    ...state,
    owners: state.owners.map(edit)
  };
};

export const removeGroup = (state, action) => {
  const index = findIndex(state.listGroups, action.group);
  return {
    ...state,
    listGroups: state.listGroups.filter((item, i) => i !== index)
  };
};

export const setView = (state, action) => {
  const dataBasic = action.group;
  return {
    ...state,
    dataBasic: dataBasic,
    owners: dataBasic.owners,
    phones: dataBasic.phones
  };
};

export const resetAll = state => {
  return {
    ...state,
    dataBasic: {},
    owners: [],
    phones: []
  };
};
