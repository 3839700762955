import * as types from 'redux/constants/Auth';

export const signIn = user => {
  return {
    type: types.SIGNIN,
    payload: user
  };
};

export const auth = login => {
  return {
    type: types.AUTHENTICATED,
    login
  };
};

export const authenticated = token => {
  return {
    type: types.AUTHENTICATED,
    token
  };
};

export const signOut = () => {
  return {
    type: types.SIGNOUT
  };
};

export const signOutSuccess = () => {
  return {
    type: types.SIGNOUT_SUCCESS
  };
};

export const signUp = user => {
  return {
    type: types.SIGNUP,
    payload: user
  };
};

export const signUpSuccess = token => {
  return {
    type: types.SIGNUP_SUCCESS,
    token
  };
};

export const signInWithGoogle = () => {
  return {
    type: types.SIGNIN_WITH_GOOGLE
  };
};

export const signInWithGoogleAuthenticated = token => {
  return {
    type: types.SIGNIN_WITH_GOOGLE_AUTHENTICATED,
    token
  };
};

export const signInWithFacebook = () => {
  return {
    type: types.SIGNIN_WITH_FACEBOOK
  };
};

export const signInWithFacebookAuthenticated = token => {
  return {
    type: types.SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
    token
  };
};

export const showAuthMessage = message => {
  return {
    type: types.SHOW_AUTH_MESSAGE,
    message
  };
};

export const hideAuthMessage = () => {
  return {
    type: types.HIDE_AUTH_MESSAGE
  };
};

export const showLoading = () => {
  return {
    type: types.SHOW_LOADING
  };
};
