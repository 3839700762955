export const COMPANY_DATABASIC = 'COMPANY_DATABASIC';
export const COMPANY_SECURITY = 'COMPANY_SECURITY';
export const COMPANY_LIST_EMAIL = 'COMPANY_LIST_EMAIL';
export const COMPANY_LIST_CONTACT = 'COMPANY_LIST_CONTACT';
export const COMPANY_LIST_ADDRESS = 'COMPANY_LIST_ADDRESS';
export const COMPANY_LIST_ACTIVITIES = 'COMPANY_LIST_ACTIVITIES';
export const COMPANY_LIST_PARTNER = 'COMPANY_LIST_PARTNER';
export const COMPANY_GET_PARTNER = 'COMPANY_GET_PARTNER';
export const COMPANY_RESET_PARTNER = 'COMPANY_RESET_PARTNER';
export const COMPANY_SET_CONTACT = 'COMPANY_SET_CONTACT';
export const COMPANY_RESET_CONTACT = 'COMPANY_RESET_CONTACT';
export const COMPANY_UPDATE_CONTACT = 'COMPANY_UPDATE_CONTACT';
export const COMPANY_NEW_ADDRESS = 'COMPANY_NEW_ADDRESS';
export const COMPANY_DELETE_CONTACT = 'COMPANY_DELETE_CONTACT';
export const COMPANY_UPDATE_ADDRESS = 'COMPANY_UPDATE_ADDRESS';
export const COMPANY_SET_ADDRESS = 'COMPANY_SET_ADDRESS';
export const COMPANY_DELETE_ADDRESS = 'COMPANY_DELETE_ADDRESS';
export const COMPANY_RESET_ADDRESS = 'COMPANY_RESET_ADDRESS';
export const COMPANY_GET_ACTIVITY = 'COMPANY_GET_ACTIVITY';
export const COMPANY_UPDATE_ACTIVIY = 'COMPANY_UPDATE_ACTIVIY';
export const COMPANY_DELETE_ACTIVITY = 'COMPANY_DELETE_ACTIVITY';
export const COMPANY_RESET_ACTIVITY = 'COMPANY_RESET_ACTIVITY';
export const COMPANY_DELETE_PARTNER = 'COMPANY_DELETE_PARTNER';
export const COMPANY_UPDATE_PARTNER = 'COMPANY_UPDATE_PARTNER';
export const COMPANY_ADD_PARTNER_PARTNER = 'COMPANY_ADD_PARTNER_PARTNER';
export const COMPANY_GET_PARTNER_PARTNER = 'COMPANY_GET_PARTNER_PARTNER';
export const COMPANY_GET_PARTNER_GETPROFILEPARTNER = 'COMPANY_GET_PARTNER_GETPROFILEPARTNER';
export const COMPANY_DELETE_PARTNER_OF_PARTNER = 'COMPANY_DELETE_PARTNER_OF_PARTNER';
export const UPDATE_PARTNER_OF_PARTNER = 'UPDATE_PARTNER_OF_PARTNER';
export const RESET_PARTNER_OF_PARTNER = 'RESET_PARTNER_OF_PARTNER';
export const GET_LIST_COMPANY = 'GET_LIST_COMPANY';
export const RESET_DATABASIC_COMPANY = 'RESET_DATABASIC_COMPANY';
export const ADD_NEW_COMPANY = 'ADD_NEW_COMPANY';
export const REMOVE_COMPANY = 'REMOVE_COMPANY';
export const DELETE_PARTNER_OF_PARTNER = 'DELETE_PARTNER_OF_PARTNER';

