import * as mutations from 'redux/mutations/User';
import data from 'redux/mocks/data-init-user.json';

const initState = {
  data
};

const person = (state = initState, action) => {
  switch (action.type) {
    case 'SET_USER':
      return mutations.setUser(state, action);

    case 'RESET_USER':
      return mutations.resetUser(state);

    default:
      return state;
  }
};

export default person;
