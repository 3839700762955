import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/home`}
          component={lazy(() => import(`./home`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/company/list`}
          component={lazy(() => import(`./company`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/company`}
          component={lazy(() => import(`./company/company-each`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/clients/list`}
          component={lazy(() => import(`./clients`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/clients`}
          component={lazy(() => import(`./clients/register`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/users/list`}
          component={lazy(() => import(`./users`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/users`}
          component={lazy(() => import(`./users/register`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/products/list`}
          component={lazy(() => import(`./products`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/products`}
          component={lazy(() => import(`./products/register`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/economic-group/list`}
          component={lazy(() => import(`./economic-group`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/economic-group`}
          component={lazy(() => import(`./economic-group/register`))}
        />

        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
