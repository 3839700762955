import {
  DashboardOutlined,
  IdcardOutlined,
  TeamOutlined,
  ShareAltOutlined,
  TagsOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

const dashBoardNavTree = [
  {
    key: 'home',
    path: `${APP_PREFIX_PATH}/home`,
    title: 'home',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'clients',
    path: `${APP_PREFIX_PATH}/clients/list`,
    title: 'clients',
    icon: TeamOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'users',
    path: `${APP_PREFIX_PATH}/users/list`,
    title: 'users',
    icon: IdcardOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'company',
    path: `${APP_PREFIX_PATH}/company/list`,
    title: 'companys',
    icon: IdcardOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'products',
    path: `${APP_PREFIX_PATH}/products/list`,
    title: 'products',
    icon: TagsOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'economic-group',
    path: `${APP_PREFIX_PATH}/economic-group/list`,
    title: 'economicGroup',
    icon: ShareAltOutlined,
    breadcrumb: false,
    submenu: []
  }
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
