import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import Person from './Person';
import Company from './Company';
import Product from './Product';
import Group from './Group';
import Client from './Client';
import User from './User';

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  client: Client,
  user: User,
  person: Person,
  company: Company,
  product: Product,
  group: Group
});

export default reducers;
