export const setUser = (state, action) => {
  return {
    ...state,
    data: { ...state.data, ...action.user }
  };
};

export const resetUser = state => {
  return {
    ...state,
    data: {}
  };
};
