import { findIndex } from 'lodash';

export const setProduct = (state, action) => {
  return {
    ...state,
    data: action.product
  };
};

export const removeProduct = (state, action) => {
  const index = findIndex(state.listProducts, action.product);
  return {
    ...state,
    listProducts: state.listProducts.filter((item, i) => i !== index)
  };
};

export const resetProduct = state => {
  return {
    ...state,
    data: ''
  };
};

export const setViewProduct = (state, action) => {
  return {
    ...state,
    data: [...state.data, action.product]
  };
};
