export const setPerson = (state, action) => {
  return {
    ...state,
    dataBasic: { ...state.dataBasic, ...action.person }
  };
};

export const setSecurity = (state, action) => {
  return {
    ...state,
    security: { ...state.security, ...action.security }
  };
};

export const setEmail = (state, action) => {
  return {
    ...state,
    emails: [...state.emails, { ...action.email }]
  };
};

export const removeEmail = (state, action) => {
  return {
    ...state,
    emails: [...action.email]
  };
};

export const setEditEmail = (state, action) => {
  const edit = (email, i) =>
    email.key === action.email.key
      ? (state.emails[i] = { ...state.emails[i], ...action.email })
      : state.emails;
  return {
    ...state,
    emails: state.emails.map(edit)
  };
};

export const setContact = (state, action) => {
  return {
    ...state,
    phones: [...state.phones, { ...action.contact }]
  };
};

export const removeContact = (state, action) => {
  return {
    ...state,
    phones: [...action.contact]
  };
};

export const setEditContact = (state, action) => {
  const edit = (contact, i) =>
    contact.key === action.contact.key
      ? (state.phones[i] = { ...state.phones[i], ...action.contact })
      : state.phones;
  return {
    ...state,
    phones: state.phones.map(edit)
  };
};

export const setAddress = (state, action) => {
  return {
    ...state,
    addresses: [...state.addresses, { ...action.address }]
  };
};

export const removeAddress = (state, action) => {
  return {
    ...state,
    addresses: [...action.address]
  };
};

export const setEditAddress = (state, action) => {
  const edit = (address, i) =>
    address.key === action.address.key
      ? (state.addresses[i] = { ...state.addresses[i], ...action.address })
      : state.addresses;
  return {
    ...state,
    addresses: state.addresses.map(edit)
  };
};

export const resetClient = state => {
  return {
    ...state,
    dataBasic: {},
    security: {},
    emails: [],
    phones: [],
    addresses: []
  };
};

export const setClient = (state, action) => {
  const {
    img,
    name,
    nameSocial,
    email,
    dateOfBirth,
    civilState,
    cityOfBirth,
    stateOfBirth,
    schooling,
    gender,
    color,
    personPublic,
    nameMother,
    nameFather,
    pcd,
    pcdOther,
    cpf,
    passportNumber,
    voterTitle,
    rg,
    docOrgan,
    docState,
    docDate,
    security,
    emails,
    phones,
    addresses
  } = action.client;
  return {
    ...state,
    dataBasic: {
      ...state.dataBasic,
      img,
      name,
      nameSocial,
      email,
      dateOfBirth,
      civilState,
      cityOfBirth,
      stateOfBirth,
      schooling,
      gender,
      color,
      personPublic,
      nameMother,
      nameFather,
      pcd,
      pcdOther,
      cpf,
      passportNumber,
      voterTitle,
      rg,
      docOrgan,
      docState,
      docDate
    },
    security,
    emails,
    phones,
    addresses
  };
};
