import {
  GROUP_DATABASIC,
  GROUP_LIST_PHONE,
  GROUP_REMOVE_PHONE,
  GROUP_EDIT_PHONE,
  GROUP_LIST_OWNER,
  GROUP_REMOVE_OWNER,
  GROUP_EDIT_OWNER,
  GROUP_REMOVE,
  GROUP_VIEW,
  GROUP_RESET
} from '../constants/Group';
import * as mutations from 'redux/mutations';

const initState = {
  dataBasic: {},
  owners: [],
  phones: [],
  listGroups: []
};

const person = (state = initState, action) => {
  switch (action.type) {
    case GROUP_DATABASIC:
      return mutations.setDataBasic(state, action);

    case GROUP_LIST_PHONE:
      return mutations.setPhone(state, action);

    case GROUP_REMOVE_PHONE:
      return mutations.removePhone(state, action);

    case GROUP_EDIT_PHONE:
      return mutations.setEditPhone(state, action);

    case GROUP_LIST_OWNER:
      return mutations.setOwner(state, action);

    case GROUP_REMOVE_OWNER:
      return mutations.removeOwner(state, action);

    case GROUP_EDIT_OWNER:
      return mutations.setEditOwner(state, action);

    case GROUP_REMOVE:
      return mutations.removeGroup(state, action);

    case GROUP_VIEW:
      return mutations.setView(state, action);

    case GROUP_RESET:
      return mutations.resetAll(state);

    default:
      return state;
  }
};

export default person;
