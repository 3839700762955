import {
  PRODUCT_DATABASIC,
  PRODUCT_SET_PRODUCT,
  PRODUCT_RESET_PRODUCT,
  PRODUCT_REMOVE
} from '../constants/Product';
import * as mutations from 'redux/mutations';

const initState = {
  data: [],
  listProducts: []
};

const product = (state = initState, action) => {
  switch (action.type) {
    case PRODUCT_DATABASIC:
      return mutations.setProduct(state, action);

    case PRODUCT_RESET_PRODUCT:
      return mutations.resetProduct(state);

    case PRODUCT_SET_PRODUCT:
      return mutations.setViewProduct(state, action);

    case PRODUCT_REMOVE:
      return mutations.removeProduct(state, action);

    default:
      return state;
  }
};

export default product;
