import {
  PERSON_DATABASIC,
  PERSON_SECURITY,
  PERSON_LIST_EMAIL,
  PERSON_REMOVE_EMAIL,
  PERSON_EDIT_EMAIL,
  PERSON_LIST_CONTACT,
  PERSON_REMOVE_CONTACT,
  PERSON_EDIT_CONTACT,
  PERSON_LIST_ADDRESS,
  PERSON_REMOVE_ADDRESS,
  PERSON_EDIT_ADDRESS,
  PERSON_SET_CLIENT,
  PERSON_RESET_CLENT
} from '../constants/Person';
import * as mutations from 'redux/mutations';

const initState = {
  dataBasic: {},
  security: {},
  emails: [],
  addresses: [],
  phones: []
};

const person = (state = initState, action) => {
  switch (action.type) {
    case PERSON_DATABASIC:
      return mutations.setPerson(state, action);

    case PERSON_SECURITY:
      return mutations.setSecurity(state, action);

    case PERSON_LIST_EMAIL:
      return mutations.setEmail(state, action);

    case PERSON_REMOVE_EMAIL:
      return mutations.removeEmail(state, action);

    case PERSON_EDIT_EMAIL:
      return mutations.setEditEmail(state, action);

    case PERSON_LIST_CONTACT:
      return mutations.setContact(state, action);

    case PERSON_REMOVE_CONTACT:
      return mutations.removeContact(state, action);

    case PERSON_EDIT_CONTACT:
      return mutations.setEditContact(state, action);

    case PERSON_LIST_ADDRESS:
      return mutations.setAddress(state, action);

    case PERSON_REMOVE_ADDRESS:
      return mutations.removeAddress(state, action);

    case PERSON_EDIT_ADDRESS:
      return mutations.setEditAddress(state, action);

    case PERSON_RESET_CLENT:
      return mutations.resetClient(state);

    case PERSON_SET_CLIENT:
      return mutations.setClient(state, action);

    default:
      return state;
  }
};

export default person;
