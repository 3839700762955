import {
  COMPANY_DATABASIC,
  COMPANY_LIST_CONTACT,
  COMPANY_LIST_ACTIVITIES,
  COMPANY_LIST_PARTNER,
  COMPANY_SET_CONTACT,
  COMPANY_RESET_CONTACT,
  COMPANY_GET_PARTNER,
  COMPANY_RESET_PARTNER,
  COMPANY_UPDATE_CONTACT,
  COMPANY_NEW_ADDRESS,
  COMPANY_DELETE_CONTACT,
  COMPANY_DELETE_ADDRESS,
  COMPANY_UPDATE_ADDRESS,
  COMPANY_SET_ADDRESS,
  COMPANY_RESET_ADDRESS,
  COMPANY_DELETE_ACTIVITY,
  COMPANY_GET_ACTIVITY,
  COMPANY_RESET_ACTIVITY,
  COMPANY_UPDATE_ACTIVIY,
  COMPANY_DELETE_PARTNER,
  COMPANY_UPDATE_PARTNER,
  COMPANY_ADD_PARTNER_PARTNER,
  COMPANY_GET_PARTNER_PARTNER,
  COMPANY_GET_PARTNER_GETPROFILEPARTNER,
  COMPANY_DELETE_PARTNER_OF_PARTNER,
  UPDATE_PARTNER_OF_PARTNER,
  RESET_PARTNER_OF_PARTNER,
  RESET_DATABASIC_COMPANY,
  ADD_NEW_COMPANY,
  REMOVE_COMPANY,
  DELETE_PARTNER_OF_PARTNER
} from '../constants/Company';
import { findIndex } from 'lodash';
const initState = {
  databasic: {},
  listAddress: [],
  listContact: [],
  partners: [],
  activities: [],

  listCompanys: [],
  tablePane: []
};

const buildNewPartner = (key, data, partner) => {
  let myPartners = [];
  if (key == undefined) {
    return [
      ...data,
      {
        ...partner,
        partners: []
      }
    ];
  }

  for (let eachData of data) {
    if (eachData.key == key) {
      const newPartner = {
        ...partner,
        partners: []
      };

      myPartners.push({
        ...eachData,
        partners:
          eachData.partners.length > 0
            ? [...eachData.partners, newPartner]
            : [newPartner]
      });
    } else {
      myPartners.push({
        ...eachData,
        partners: eachData.partners
          ? buildNewPartner(key, eachData.partners, partner)
          : []
      });
    }
  }
  return myPartners;
};

const handleGetEspecificPartner = (array, key, value, newValues) => {
  array.some(function iter(a) {
    if (a[key] == value) {
      Object.assign(a, newValues);
      return true;
    }
    return Array.isArray(a.partners) && a.partners.some(iter);
  });
  return array;
};

const recursiveRemove = (list, key) => {
  return list
    .map(item => {
      return { ...item };
    })
    .filter(item => {
      if ('partners' in item) {
        item.partners = recursiveRemove(item.partners, key);
      }
      return item.key !== key;
    });
};

const company = (state = initState, action) => {
  switch (action.type) {
    case 'SET_TABLEPANE':
      return {
        ...state,
        tablePane: [{ ...action.tablePane }]
      };
    case 'REMOVE_TABLEPANE':
      return {
        ...state,
        tablePane: state.tablePane.filter(item => item.key != action.key)
      };
    case 'UPDATE_TABLEPANE':
      return {
        ...state,
        tablePane: state.tablePane.filter((item, index) =>
          item.key == action.tablePane.key
            ? Object.assign(item, action.tablePane)
            : item
        )
      };

    case 'GET_LIST_COMPANY':
      const listAddress = action.company.listAddress;
      const listContact = action.company.listContact;
      const partners = action.company.partners;
      const activities = action.company.activities;

      return {
        ...state,
        databasic: { ...state.databasic, ...action.company },
        listAddress: [...listAddress],
        listContact: [...listContact],
        partners: [...partners],
        activities: [...activities]
      };

    case RESET_DATABASIC_COMPANY:
      return {
        ...state,
        databasic: {},
        listAddress: [],
        listContact: [],
        partners: [],
        activities: []
      };

    case COMPANY_DATABASIC:
      return {
        ...state,
        databasic: { ...state.databasic, ...action.company }
      };

    case ADD_NEW_COMPANY:
      return {
        ...state,
        listCompanys: [
          ...state.listCompanys,
          {
            ...action.company,
            listContact: [],
            listAddress: [],
            partners: [],
            activities: []
          }
        ]
      };

    case REMOVE_COMPANY:
      const index = findIndex(state.listCompanys, action.company);
      return {
        ...state,
        listCompanys: state.listCompanys.filter((item, i) => i !== index)
      };

    case COMPANY_LIST_ACTIVITIES:
      return {
        ...state,
        activities: [...state.activities, { ...action.activity }]
      };
    case COMPANY_DELETE_ACTIVITY:
      return {
        ...state,
        activities: [
          ...state.activities.filter(
            (item, index) => item.key !== action.activity.key
          )
        ]
      };
    case COMPANY_GET_ACTIVITY:
      return {
        ...state,
        getActivity: { ...action.activity }
      };
    case COMPANY_UPDATE_ACTIVIY:
      return {
        ...state,
        activities: [
          ...state.activities.map((item, index) =>
            item.key == action.activity.key
              ? {
                  ...item,
                  ...action.activity
                }
              : item
          )
        ]
      };
    case COMPANY_RESET_ACTIVITY:
      return { ...state, getActivity: null };

    case COMPANY_RESET_ADDRESS:
      return { ...state, getAddress: null };
    case COMPANY_SET_ADDRESS:
      return {
        ...state,
        getAddress: { ...action.address }
      };
    case COMPANY_UPDATE_ADDRESS:
      return {
        ...state,
        listAddress: [
          ...state.listAddress.map((item, index) =>
            item.key == action.address.key
              ? {
                  ...item,
                  ...action.address
                }
              : item
          )
        ]
      };
    case COMPANY_NEW_ADDRESS:
      return {
        ...state,
        listAddress: [...state.listAddress, { ...action.address }]
      };
    case COMPANY_DELETE_ADDRESS:
      return {
        ...state,
        listAddress: [
          ...state.listAddress.filter(
            (item, index) => item.key !== action.address.key
          )
        ]
      };

    case COMPANY_LIST_CONTACT:
      return {
        ...state,
        listContact: [...state.listContact, { ...action.contact }]
      };
    case COMPANY_SET_CONTACT:
      return {
        ...state,
        getContact: { ...action.contact }
      };
    case COMPANY_UPDATE_CONTACT:
      return {
        ...state,
        listContact: [
          ...state.listContact.map((item, index) =>
            item.key == action.contact.key
              ? {
                  ...item,
                  ...action.contact
                }
              : item
          )
        ]
      };
    case COMPANY_DELETE_CONTACT:
      return {
        ...state,
        listContact: [
          ...state.listContact.filter(
            (item, index) => item.key !== action.contact.key
          )
        ]
      };
    case COMPANY_RESET_CONTACT:
      return { ...state, listContact: [] };

    case COMPANY_LIST_PARTNER:
      return { ...state, partners: [...state.partners, { ...action.partner }] };
    case COMPANY_RESET_PARTNER:
      return { ...state, getPartner: {} };
    case COMPANY_GET_PARTNER:
      return { ...state, getPartner: { ...action.partner } };
    case COMPANY_UPDATE_PARTNER:
      return {
        ...state,
        partners: [
          ...state.partners.map((item, index) =>
            item.key === action.partner.key
              ? { ...item, ...action.partner }
              : item
          )
        ]
      };
    case COMPANY_DELETE_PARTNER:
      return {
        ...state,
        partners: [
          ...state.partners.filter(
            (item, index) => item.key !== action.partner.key
          )
        ]
      };
    case COMPANY_ADD_PARTNER_PARTNER:
      return {
        ...state,
        partners: buildNewPartner(
          action.partner.key,
          state.partners,
          action.partnerPartner
        )
      };

    case COMPANY_GET_PARTNER_PARTNER:
      return { ...state, partnersAll: state.partners };
    case RESET_PARTNER_OF_PARTNER:
      return { ...state, partnerPartner: null };
    case COMPANY_GET_PARTNER_GETPROFILEPARTNER:
      return { ...state, partnerPartner: { ...action.partner } };
    case COMPANY_DELETE_PARTNER_OF_PARTNER:
      return {
        ...state,
        partners: [
          ...state.partners.map((item, index) =>
            item.key === action.mainPartner.key
              ? {
                  ...item,
                  partners: item.partners.filter(
                    (item, index) => item.key !== action.thisPartner.key
                  )
                }
              : item
          )
        ]
      };
    case UPDATE_PARTNER_OF_PARTNER:
      return {
        ...state,
        partners: handleGetEspecificPartner(
          state.partners,
          'key',
          action.thisPartner.key,
          { ...action.thisPartner }
        )
      };
    case DELETE_PARTNER_OF_PARTNER:
      return {
        ...state,
        partners: recursiveRemove(state.partners, action.partner.key)
      };
    case 'ALL_PARTNER_TABS':
      return {
        ...state,
        allPartnerTabs: [...state.allPartnerTabs, ...action.partner]
      };

    default:
      return state;
  }
};

export default company;
