export const setClient = (state, action) => {
  return {
    ...state,
    data: { ...state.data, ...action.client }
  };
};

export const setEmail = (state, action) => {
  return {
    ...state,
    data: { ...state.data, emails: [...state.data.emails, { ...action.email }] }
  };
};

export const removeEmail = (state, action) => {
  return {
    ...state,
    data: { ...state.data, emails: [...action.email] }
  };
};

export const editEmail = (state, action) => {
  const edit = (email, i) =>
    email.key === action.email.key
      ? (state.data.emails[i] = { ...state.data.emails[i], ...action.email })
      : state.data.emails;
  return {
    ...state,
    data: { ...state.data, emails: state.emails.map(edit) }
  };
};

export const setPhone = (state, action) => {
  return {
    ...state,
    data: { ...state.data, phones: [...state.data.phones, { ...action.phone }] }
  };
};

export const removePhone = (state, action) => {
  return {
    ...state,
    data: { ...state.data, phones: [...action.phone] }
  };
};

export const editPhone = (state, action) => {
  const edit = (phone, i) =>
    phone.key === action.phone.key
      ? (state.data.phones[i] = { ...state.data.phones[i], ...action.phone })
      : state.data.phones;
  return {
    ...state,
    data: { ...state.data, phones: state.data.phones.map(edit) }
  };
};

export const resetClient = state => {
  return {
    ...state,
    data: {}
  };
};
