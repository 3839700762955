import ptLang from './entries/pt_BR';
import enLang from './entries/en_US';
import zhLang from './entries/zh_CN';
import frLang from './entries/fr_FR';
import jaLang from './entries/ja_JP';

const AppLocale = {
  pt: ptLang,
  en: enLang,
  zh: zhLang,
  fr: frLang,
  ja: jaLang
};

export default AppLocale;
